/**
 * @description Add all browser specific polyfills here
 *
 */

import 'intersection-observer'
import smoothscroll from 'smoothscroll-polyfill'
smoothscroll.polyfill()

// IE-11 Polyfill for object-fit
if ('objectFit' in document.documentElement.style === false) {
  require('objectFitPolyfill')

  // Helper function to apply polyfill
  const applyPolyfill = () => {
    ;(window as any).objectFitPolyfill()
    //
    console.log('Applying objectFitPolyfill')
  }

  // We add
  window.addEventListener('load', () => applyPolyfill)

  setTimeout(applyPolyfill, 50)
}

if ('Element' in (window as any) && !Element.prototype.closest) {
  ;(window as any).Element.prototype.closest = function (selector: Element) {
    var matches = (this.document || this.ownerDocument).querySelectorAll(selector),
      i,
      el = this
    do {
      i = matches.length
      while (--i >= 0 && matches.item(i) !== el) {}
    } while (i < 0 && (el = el.parentElement))
    return el
  }

  console.log('Applying Element.closest polyfill')
}
